import axios from '../js/request'
import {format} from 'date-fns'
import Vuex from 'vuex'
import upload from "@/resources/js/upload";
import DateUtil from "@/resources/js/dateutil";

const config = require('../../resources/js/config');
export default {
  install(Vue) {
    // 请求
    Vue.prototype.$request = {
      post: (param) => {
        console.log("--------------------------",param)
        return axios.request.doPost(param)
      },
      uploadIdCard: (param) => {
        return axios.request.uploadIdCard(param)
      }
    };
    // 全局事件管理器
    Vue.prototype.$Bus = new Vue();

    Vue.mixin({
      data() {
        return {
          fileUploadFunction: upload,
        }
      },
      directives: {
      },
      filters: {
        dateString: function (value, patten = 'yyyy-MM-dd HH:mm:ss') {
          if (!value) {
            return '';
          }
          if (typeof value === 'string' && value.includes('CST')) {
            const dateStr = value.split(' ');
            const strGMT = dateStr[0] + ' ' + dateStr[1] + ' ' + dateStr[2]
              + ' ' + dateStr[5] + ' ' + dateStr[3] + ' GMT+0800';
            const date = new Date(Date.parse(strGMT));
            return format(date, patten)
          }
          return format(new Date(value), patten)
        }
      },
      beforeMount() {
      },
      methods: {
        doPrivileged(func, args) {
          // 校验当前用户是否登录
          if (this.user) {
            func.call(null, args);
          } else {
            // 登录
            this.$confirm('您暂未登录，是否立即登录?', '提示', {
              confirmButtonText: '前去登录',
              cancelButtonText: '暂不登录',
              type: 'warning'
            }).then(() => {
              this.goPage('/login')
            })
          }
        },
        getQuery() {
          const query = this.$route.query;
          if (query) {
            Object.keys(query).forEach(key => {
              let parse;
              try {
                parse = JSON.parse(query[key])
              } catch (e) {
                parse = query[key]
              }
              query[key] = parse
            })
          }
          console.log(query)
          return query
        },
        goPage(...arg) {
          return this.$router.push(...arg)
        },
        openPage(...arg) {
          let routeUrl = this.$router.resolve(...arg);
          window.open(routeUrl.href, '_blank');
        },
        delayToPage(...arg) {
          setTimeout(() => {
            this.goPage(...arg)
          }, 1500)
        }
      },
      computed: {
        ...Vuex.mapGetters({
          user: 'account/getUser'
        }),

        timestampToTime() {
          return time => {
            return DateUtil.formatDuring(time);
          }
        },
        parseDate() {
          return value => {
            if (!value) {
              return '';
            }
            if (typeof value === 'string' && value.includes('CST')) {
              const dateStr = value.split(' ');
              const strGMT = dateStr[0] + ' ' + dateStr[1] + ' ' + dateStr[2]
                + ' ' + dateStr[5] + ' ' + dateStr[3] + ' GMT+0800';
              return new Date(Date.parse(strGMT))
            }
            return new Date(value)
          }
        },
        dateToString() {
          return (value, patten = 'yyyy-MM-dd HH:mm:ss') => {
            if (!value) {
              return '';
            }
            let date = this.parseDate(value);
            if (date) {
              return format(date, patten);
            }
            return '';
          }
        },
        DEFAULT_AVATAR_PATH() {
          return require('../../../public/images/user/avatar.png')
        }
      }
    })
  }
}
