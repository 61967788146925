require('./resources/js/prototypeEnhance')
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './initdata'
import common from './resources/plugin/common'
import language from './resources/languages/index'
import please from "./components/XXXX/test/please";

require('./resources/plugin/element-ui');

Vue.config.productionTip = false;

Vue
.use(common);
//注册全局组件
Vue.component("s-please",please);

language.loadLanguageAsync().then(() => {
  new Vue({
    router,
    i18n: language.i18n,
    store,
    render: h => h(App)
  }).$mount('#app')
});
