import axios from 'axios'
import config from './config'
import {Message, MessageBox} from 'element-ui'
import router from '@/router/index'
import {AESUtil} from '@/resources/js/cryptoender'
import md5 from 'js-md5'

const repeatedSubmitCache = [];

class HttpRequest {
  constructor(baseUrl, APP_ID = '', isDebug = false) {
    this.baseUrl = baseUrl
    this.APP_ID = APP_ID
    this.isDebug = isDebug
    // this.queue = {}
    // console.log("baseurl="+baseUrl +"APPID="+APP_ID+"ISDEBUG="+isDebug);
  }

  getInsideConfig() {
    return {
      baseURL: this.baseUrl,
      headers: {
        tokenid: localStorage.getItem("token")
      }
    }
  }

  stringify(obj) {
    if (obj instanceof Array) {
      const arr = []
      for (let i = 0; i < obj.length; i++) {
        arr[i] = this.stringify(obj[i])
      }
      return arr
    } else if (typeof obj === 'object') {
      for (const key in obj) {
        obj[key] = this.stringify(obj[key])
      }
    } else if (typeof obj === 'number') {
      obj = obj + ''
    }
    return obj
  }

  stopRepeatRequest(request) {
    let cancel
    request.cancelToken = new axios.CancelToken(function (c) {
      cancel = c;
    });
    let keyData = JSON.parse(JSON.stringify(request.data));
    delete keyData.timestamp;
    let keyJson = JSON.stringify(keyData);
    if (repeatedSubmitCache.includes(keyJson)) {
      if (cancel) {
        cancel('您的操作太频繁，请稍后重试！');
        return;
      }
    }
    repeatedSubmitCache.push(keyJson);
  }

  allowRequest(config) {
    //let dataJson = AESUtil.aesDecrypt(JSON.parse(config.data).params);
    let dataJson = config.data;
    let keyData = JSON.parse(dataJson);
    delete keyData.timestamp;
    let keyJson = JSON.stringify(keyData);
    let index;
    if ((index = repeatedSubmitCache.indexOf(keyJson)) !== -1) {
      repeatedSubmitCache.splice(index, 1);
    }
  }

  delayAllowRequest(config) {
    setTimeout(() => {
      this.allowRequest(config);
    }, 1000);
  }

  interceptors(instance) {
    const self = this
    instance.interceptors.request.use(request => {
      request.data.appid = self.APP_ID;
      request.data.timestamp = new Date().getTime();
      request.data = self.stringify(request.data);
      if (self.isDebug) {
        console.debug(request.data);
      }
      // self.stopRepeatRequest(request);
      // self.encryptParams(request);
      return request
    }, error => {
      Message.error('请求网络异常，请稍后再试!')
      console.error(error)
      return Promise.reject(error)
    })
    instance.interceptors.response.use(response => {
      self.delayAllowRequest(response.config);
      if (self.isDebug) {
        console.debug(response)
      }
      return response
    }, error => {
      if (axios.isCancel(error)) {
        return Promise.reject();
      }
      self.delayAllowRequest(error.config);
      let errorCode = error && error.response && error.response.status || null;
      if (errorCode === 405) {
        Message.error('请求不被允许')
      } else if (errorCode === 404) {
        Message.error('内容未找到')
      } else {
        Message.error('请求网络异常，请稍后再试!')
      }
      return Promise.reject(error)
    })
  }

  encryptParams(options) {
    if (options.data) {
      let params = options.data;
      let encrypt = AESUtil.aesEncrypt(JSON.stringify(params))
      let sign = md5(`params=${encrypt}&key=${config.secretKey}`).toUpperCase();
      options.data = {
        params: encrypt,
        sign,
      };
      options.headers.sign = sign;
    }
  }

  request(options) {
    options = {
      ...this.getInsideConfig(),
      ...options
    }
    const instance = axios.create(options)
    this.interceptors(instance, options.url)
    return instance(options).then(res => {
      let {headers: {token}} = res;
      if (token) {
        // 更新token
        localStorage.setItem("token", token);
      }
      if (res.status === 200) {
        let data = res.data;
        data.retCode = String(data.retcode);
        data.retDesc = String(data.retdesc);
        delete data.retcode;
        delete data.retdesc;
        if (data.retCode === '8888') {
          return Promise.resolve(data);
        } else if (data.retCode === '9999') {
          Message.error(data.retDesc || '操作失败');
          return Promise.reject(data);
        } else if (data.retCode === '9403') {
          MessageBox.confirm(`您暂未通过实名认证，请通过实名认证后操作！`, '提示', {
            confirmButtonText: '去认证',
            cancelButtonText: '取消',
            showCancelButton: true,
          }).then(res => {
            if (res === 'confirm') {
              router.push(data.retDesc);
            }
          });
          return Promise.reject(data);
        } else if (data.retCode === '9404') {
          MessageBox.confirm(`您还未入驻，不能发布出售信息，请先完善店铺信息！`, '提示', {
            confirmButtonText: '去入驻',
            cancelButtonText: '取消',
            showCancelButton: true,
          }).then(res => {
            if (res === 'confirm') {
              router.push('/shop/index');
            }
          });
          return Promise.reject(data);
        } else if (data.retCode === '9405') {
          MessageBox.alert(`您的实名认证还在审核中，请等待完成再操作！`, '提示', {
            confirmButtonText: '确认',
          });
          return Promise.reject(data);
        } else if (data.retCode === '9406') {
          MessageBox.alert(`您还未完成实名认证，请先完成实名认证！`, '提示', {
            confirmButtonText: '确认',
          });
          return Promise.reject(data);
        } else if (data.retCode === '9501') {
          MessageBox.confirm(`您还设置支付密码，请先设置支付密码！`, '提示', {
            confirmButtonText: '去设置',
            cancelButtonText: '取消',
            showCancelButton: true,
          }).then(res => {
            if (res === 'confirm') {
              router.push('/user/account/paypwd');
            }
          });
          return Promise.reject(data);
        } else if (data.retCode === '9003') {
          MessageBox.confirm('为了方便他人和您直接联系，请先完善联系方式及用户地区，QQ号码、微信号、邮箱至少补充完整两种。',
            {
              confirmButtonText: '马上完善',
              showCancelButton: false,
              cancelButtonText: '取消',
              type: 'warning'
            }).then((res) => {
            if (res === 'confirm') {
              router.push('/user-center');
            }
          })
          return Promise.reject(data);
        } else if (data.retCode === '9004') {
          MessageBox.alert('商品未通过审核，无法上架!', {
            confirmButtonText: '确认',
            type: 'warning'
          });
          return Promise.reject(data);
        } else if (data.retCode === '9005') {
          let userId = data.data;
          router.push(`/loginRestrictions/${userId}`);
          return Promise.reject(data);
        } else if (data.retCode === '9001') {
          localStorage.setItem("IpLimitAccessReason",
            JSON.stringify(data.data));
          router.push('/limitAccess');
          return Promise.reject(data);
        } else {
          return Promise.reject(data);
        }
      }
    })
  }

  doPost(params) {
    const requestParams = {}
    requestParams.method = 'post'
    requestParams.url = config.URL
    requestParams.data = params
    return this.request(requestParams)
  }

  uploadIdCard(params) {
    const requestParams = {}
    requestParams.method = 'post'
    requestParams.url = '/uploads/uploadIdCard'
    requestParams.data = params
    return this.request(requestParams)
  }
}

const request = new HttpRequest(config.BASE_URL, config.APP_ID, config.isDebug)

export default {
  request: request
}
